<template>
  <div class="thumbnail">
    <div class="soporte" slot="start" v-for="image in images" :key="image">
      <img :src="image" alt="" />
      <ion-button class="quit-img" fill="clear" @click="quitImage(image)"
        ><ion-icon slot="icon-only" :icon="trashOutline"></ion-icon
      ></ion-button>
    </div>
  </div>
</template>
<script>
import {IonButton, IonIcon} from '@ionic/vue';
import { mapActions, mapGetters } from 'vuex';
import { trashOutline} from "ionicons/icons";
export default {
    name:'images-component',
    components:{
        IonButton, IonIcon
    },
    data(){
        return{
            trashOutline
        }
    },
    computed:{
        ...mapGetters("item",["images"]),
    },
    methos:{
        ...mapActions("item", ["quitImages"]),
        quitImage(image){
            this.quitImages(image);
        }
    }

}
</script>

<style scoped>
.thumbnail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0.1em solid #ddd;
  margin: 0.2em;
  margin-left: 0.7em;
  padding: 0.1em;
  justify-content: space-between;
}
.soporte {
  height: 100px;
  width: 100px;
  margin: 0.1em;
  margin-bottom: 10%;
}
.soporte img {
  height: 100%;
  width: 100%;
  object-fit: cover !important;
}
.quit-img {
  margin-top: -10%;
}
</style>