<template>
  <base-layout>
    <actividad-overview></actividad-overview>
  </base-layout>
</template>
<script>
import ActividadOverview from "../components/actividad/ActividadOverview.vue";
export default {
  name: "actividad-page",
  components: { ActividadOverview }  
};
</script>