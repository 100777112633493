<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>{{ actividad.actividad }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-card>
        <ion-card-content>
          <ion-item>
            <ion-label position="stacked">Cliente:</ion-label>
            <ion-text>Cliente de la obra</ion-text>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Contacto:</ion-label>
            <ion-text>Contacto Cliente</ion-text>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Referencia:</ion-label>
            <ion-text>Item : {{ actividad.item }}</ion-text>
          </ion-item>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-content>
          <ion-item>
            <ion-label position="stacked">Responsable Beling:</ion-label>
            <ion-text>{{ actividad.proceso }}</ion-text>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Fecha Inicio:</ion-label>
            <ion-text>{{ actividad.fecha_inicio }}</ion-text>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Fecha Final:</ion-label>
            <ion-text>{{ actividad.fecha_final || "" }}</ion-text>
          </ion-item>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-content>
          <ion-item lines="none" class="label-item">
            <ion-label>Descripción del Item</ion-label>
          </ion-item>
          <ion-item lines="none">
            <p>{{ actividad.description }}</p>
          </ion-item>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-title>Materiales</ion-card-title>
        <ion-card-content>
          <div class="material-container">
            <ion-list lines="none">
              <ion-item
                v-for="item in materiales"
                :key="item.id"
                class="material-item"
              >
                <ion-label class="label-item">{{ item.name }}</ion-label>
                <ion-note class="label-cantidad" slot="end">{{
                  item.cantidad
                }}</ion-note>
              </ion-item>
            </ion-list>
          </div>
          <ion-item lines="none">
            <ion-button
              slot="end"
              type="button"
              fill="clear"
              @click="openModal(1)"
            >
              <ion-icon slot="start" :icon="createOutline"></ion-icon>
              <ion-label>Editar Materiales</ion-label>
            </ion-button>
          </ion-item>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-title>Equipos</ion-card-title>
        <ion-card-content>
          <div class="material-container">
            <ion-list lines="none">
              <ion-item
                v-for="item in equipos"
                :key="item.id"
                class="material-item"
              >
                <ion-label class="label-item">{{ item.name }}</ion-label>
                <ion-note class="label-cantidad" slot="end">{{
                  item.cantidad
                }}</ion-note>
              </ion-item>
            </ion-list>
          </div>
          <ion-item lines="none">
            <ion-button
              slot="end"
              type="button"
              fill="clear"
              @click="openModal(2)"
            >
              <ion-icon slot="start" :icon="createOutline"></ion-icon>
              <ion-label>Editar Equipo</ion-label>
            </ion-button>
          </ion-item>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-title>Personal</ion-card-title>
        <ion-card-content>
          <personal-component></personal-component>
          <ion-item lines="none">
            <ion-button
              slot="end"
              type="button"
              fill="clear"
              @click="openModal(3)"
            >
              <ion-icon slot="start" :icon="createOutline"></ion-icon>
              <ion-label>Editar Personal</ion-label>
            </ion-button>
          </ion-item>
        </ion-card-content>        
      </ion-card>
      <images-actividad :actividadId="actividad.id"></images-actividad>
      <ion-card>
        <ion-card-title>Observaciones</ion-card-title>
        <ion-card-content>
          <ion-item lines="none">
            <p>{{ observaciones }}</p>
          </ion-item>
          <ion-item lines="none">
            <ion-button
              slot="end"
              type="button"
              fill="clear"
              @click="openModal(4)"
            >
              <ion-icon slot="start" :icon="createOutline"></ion-icon>
              <ion-label>Editar Obs</ion-label>
            </ion-button>
          </ion-item>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-title>Recibe</ion-card-title>
        <ion-card-content>
          <ion-list>
            <ion-item>
              <ion-avatar slot="start">
                <img :src="actividad.firma_cliente" />
              </ion-avatar>
              <ion-label>
                <h2>{{ actividad.responsable }}</h2>
                <h3>{{ actividad.documento }}</h3>
                <p>{{ actividad.cargo }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-title>Responsable Beling</ion-card-title>
        <ion-card-content>
          <ion-list>
            <ion-item>
              <ion-avatar slot="start">
                <img :src="actividad.firma_ingenieria" />
              </ion-avatar>
              <ion-label>
                <h2>{{ actividad.proceso }}</h2>
                <p>Ingeniero Beling</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-card-content>
    <ion-button type="button" fill="clear" @click="regresar">
      <ion-icon slot="start" :icon="arrowUndoOutline"></ion-icon>
      <ion-label>Regresar</ion-label>
    </ion-button>
    <ion-button type="button" fill="clear" @click="openModal(5)">
      <ion-icon slot="start" :icon="arrowUndoOutline"></ion-icon>
      <ion-label>Pdf</ion-label>
    </ion-button>
  </ion-card>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonCardContent,
  IonText,
  IonNote,
  IonList,
  IonAvatar,
  IonButton,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { createOutline, arrowUndoOutline } from "ionicons/icons";
import MaterialModal from "./modal/MaterialModal.vue";
import EquipoModal from "./modal/EquipoModal.vue";
import ImagesActividad from "./ImagesActividad.vue";
import PersonalModal from "./modal/PersonalModal.vue";
import PersonalComponent from '../common/PersonalComponent.vue';
import ObservacionesModal from './modal/ObservacionesModal.vue';
import PdfModal from './modal/PdfModal.vue';

export default {
  name: "actividad-overview",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonCardContent,
    IonText,
    IonNote,
    IonList,
    IonAvatar,
    IonButton,
    IonIcon,
    ImagesActividad,
    PersonalComponent,
  },
  computed: {
    ...mapGetters("actividad", ["actividad", "currentModal", "images", "observaciones"]),
    ...mapGetters("item", ["materiales", "equipos"]),
    material() {
      return JSON.parse(this.actividad.materiales);
    },
    equipo() {
      return JSON.parse(this.actividad.equipo);
    },
    personal() {
      return JSON.parse(this.actividad.personal);
    },
  },
  data() {
    return {
      createOutline,
      arrowUndoOutline
    };
  },
  mounted() {
    this.setObservaciones(this.actividad.observaciones)
    this.setMateriales(this.material);
    this.setEquipos(this.equipo);
    this.setEmpleados(this.personal);
    this.retrieveImages(this.actividad.id);
  },
  methods: {
    ...mapActions("actividad", ["setModal", "retrieveImages", "setObservaciones"]),
    ...mapActions("item", ["setMateriales", "setEquipos", "setEmpleados"]),
    async openModal(tipo) {
      let type = "";
      let component = null;
      if (tipo == 1) {
        type = "Materiales";
        component = MaterialModal;
      } else if (tipo === 2) {
        type = "Equipo";
        component = EquipoModal;
      } else if (tipo === 3) {
        type = "Personal";
        component = PersonalModal;
      }else if (tipo === 4) {
        type = "Observaciones";
        component = ObservacionesModal;
      }else if (tipo === 5) {
        type = "Ver Pdf";
        component = PdfModal;
      } else {
        return false;
      }

      const modal = await modalController.create({
        component: component,
        cssClass: "my-custom-class",
        componentProps: {
          title: type,
          actividadId: this.actividad.id,
        },
      });
      this.setModal(modal);
      await modal.present();
    },
    regresar() {
      this.$router.replace(`/Item/${this.actividad.item}`);
      this.setMateriales([]);
      this.setEquipos([]);
      this.setEmpleados([]);
    },
  },
};
</script>

<style scoped>
ion-card {
  margin: 5px;
  padding: 0px;
}
ion-card-title {
  font-size: 20px;
  margin-top: 15px;
}
.material-container {
  margin: 0.1em;
  margin-left: 0.3em;
  padding: 0.2em;
  justify-content: left;
}
.material-item {
  height: 35px;
}
.material-item div.item-inner {
  border-bottom: none;
}
.material-item .label-item {
  width: 90%;
  font-size: 12px;
}
.material-item .label-cantidad {
  width: 10%;
  font-size: 12px;
}
.material-container ion-note {
  font-size: 16px;
}
.personal-item {
  height: 35px;
}
.personal-item div.item-inner {
  border-bottom: none;
}
.personal-item .label-item {
  width: 70%;
  font-size: 12px;
}
.personal-item .label-identificacion {
  width: 30%;
  font-size: 12px;
}
</style>