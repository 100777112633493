<template>
  <div class="personal-container">
    <ion-list lines="none">
      <ion-item v-for="item in empleados" :key="item.id" class="personal-item">
        <ion-label class="label-item">{{ item.nombres }}</ion-label>
        <ion-note class="label-identificacion" slot="end">{{
          item.identificacion
        }}</ion-note>
      </ion-item>
    </ion-list>
  </div>
</template>

<script>
import {IonList, IonItem, IonLabel, IonNote} from '@ionic/vue'
import { mapGetters } from 'vuex';
export default {
    name: 'personal-component',
    components: { IonList, IonItem, IonLabel, IonNote },
    computed:{
        ...mapGetters("item",["empleados"])
    },
    
};
</script>

<style scoped>
.personal-container {
  margin: 0.1em;
  margin-left: 0.1em;
  padding: 0.1em;
  justify-content: left;
}
.personal-item {
  height: 35px;
}
.personal-item div.item-inner {
  border-bottom: none;
}
.personal-item .label-item {
  width: 90%;
  font-size: 12px;
}
.personal-item .label-cantidad {
  width: 10%;
  font-size: 12px;
}
.personal-container ion-note {
  font-size: 16px;
}
.personal-item {
  height: 35px;
}
.personal-item div.item-inner {
  border-bottom: none;
}
.personal-item .label-item {
  width: 70%;
  font-size: 12px;
}
.personal-item .label-identificacion {
  width: 30%;
  font-size: 12px;
}
</style>