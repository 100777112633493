<template>
  <ion-card>
    <ion-card-title>Evidencias</ion-card-title>
    <ion-card-content>
      <ion-list>
        <ion-card v-for="item in images" :key="item.id">
          <ion-img :src="item.rute" />
          <ion-button
            class="quit-img"
            fill="clear"
            @click="deleteImage(item.id)"
            ><ion-icon slot="icon-only" :icon="trashOutline"></ion-icon
          ></ion-button>
        </ion-card>
      </ion-list>
      <ion-item lines="none">
        <ion-button slot="end" type="button" fill="clear" @click="openModal()">
          <ion-icon slot="start" :icon="imageOutline"></ion-icon>
          <ion-label>Agregar Imagenes</ion-label>
        </ion-button>
      </ion-item>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonImg,
  IonButton,
  IonIcon,
  toastController,
  IonItem,
  IonLabel,
  modalController,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { trashOutline, imageOutline } from "ionicons/icons";
import ImageModal from './modal/ImageModal.vue';

export default {
  name: "images-actividad",
  props:{
    actividadId:{type : Number}
  },
  components: {
    IonCard,
    IonCardTitle,
    IonCardContent,
    IonList,
    IonImg,
    IonButton,
    IonIcon,
  IonItem,
  IonLabel,
  },
  computed: {
    ...mapGetters("actividad", ["images","currentModal"]),
  },
  data() {
    return {
      trashOutline,
      toatsMessage: "",
      toatsColor: "",
      imageOutline
    };
  },
  methods: {
    ...mapActions("actividad", ["deleteImage", "setModal"]),

    async delImage(id) {
      const result = await this.deleteImage(id);

      if (result.status === 200) {
        this.toatsMessage = "Imagen eliminada exitosamente";
        this.toatsColor = "light";
        this.openToast();
      } else if (result.status === 500) {
        this.toatsColor = "danger";
        this.toatsMessage = `¡Error interno del servidor!.<br>Contacte al administrador`;
        this.openToast();
      } else if (result.status === 422) {
        let errorMessage = "";
        const errors = await JSON.parse(JSON.stringify(result.data.errors));
        for (const i in errors) {
          errorMessage += `${errors[i][0]}\n`;
        }
        this.toatsColor = "danger";
        this.toatsMessage = `No se ha enviado toda la información<br>¡Erores!<br>${errorMessage}`;
        await this.openToast();
      } else {
        this.toatsColor = "danger";
        this.toatsMessage = "Error. No se ha realizado el registro";
        this.openToast();
      }
    },

async openModal() {      
      const modal = await modalController.create({
        component:ImageModal,
        cssClass: "my-custom-class",
        componentProps: {
          title: "Evidencias",
          actividadId: this.actividadId,
        },
      });
      this.setModal(modal);
      await modal.present();
    },

    async openToast() {
      const toast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 2000,
      });
      return toast.present();
    },
  },
};
</script>
<style scoped>
ion-card {
  margin: 5px;
  padding: 0px;
}
ion-card-title {
  font-size: 20px;
  margin-top: 15px;
}

ion-img {
  max-height: 150px;
  object-fit: cover;
}
</style>
