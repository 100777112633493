<template>
  <ion-item lines="inset">
    <ion-label position="floating">Observaciones</ion-label>
    <ion-textarea
      rows="3"
      inputmode="text"
      v-model="obs"
      @change="handleInputChange"
    ></ion-textarea>
  </ion-item>
</template>

<script>
import {IonItem, IonLabel, IonTextarea} from '@ionic/vue';
import { mapActions } from 'vuex';
export default {
    name:'observaciones-component',
    props:{
        observaciones:{type: String}
    },
    data(){
        return{
            obs: ''
        }
    },
    components:{ IonItem, IonLabel, IonTextarea},
    mounted(){
        this.changeObs();
        this.setObservaciones(this.obs)
    },
    methods:{
        ...mapActions("actividad", ["setObservaciones"]),
        async handleInputChange(){                      
           await this.setObservaciones(this.obs);
        },
        changeObs(){
            this.obs = this.observaciones;
        }
    }
};
</script>

<style>
</style>