<template>
  <base-layout>
    <ion-card>
      <ion-card-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button
              type="button"
              fill="clear"
              color="primary"
              @click="dismissModal()"
            >
              <ion-icon slot="start" :icon="closeOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-card-header>
      <ion-card-content>
        <ion-button type="button" fill="clear" color="primary" @click="generateReport()">
          <ion-icon slot="start" :icon="readerOutline"></ion-icon>
        </ion-button>
      </ion-card-content>
    </ion-card>
    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="1100"
        :pdf-quality="2"
        :manual-pagination="false"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-format="letter"
        pdf-orientation="portrait"
        pdf-content-width="760px"
        @beforeDownload="beforeDownload($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <!-- PDF Content Here -->
          <section class="pdf-item">
            <h1>Pdf Content</h1>
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </base-layout>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { closeOutline, readerOutline } from "ionicons/icons";
import VueHtml2pdf from "vue-html2pdf";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "pdf-modal",
  props: {
    title: String,
    actividadId: Number,
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    VueHtml2pdf
  },
  data() {
    return { show: false, closeOutline, readerOutline };
  },
  computed: {
    ...mapGetters("actividad", ["actividad", "currentModal"]),

    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: this.archivo,
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          allowTaint: true,
          useCORS: true,
        },
      };
    },

    archivo() {
      return this.actividad.actividad;
    },
  },
  methods: {
    ...mapActions("actividad", ["setModal"]),

    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFont("verdana", "bold", "400");
            pdf.setFontSize(10);
            pdf.setTextColor(100);
            pdf.text(
              "Página " + i + " de " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 5
            );
          }
        })
        .save();
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    dismissModal() {
      if (this.currentModal) {
        this.currentModal.dismiss().then(async () => {
          await this.setModal(null);
        });
      }
    },
  },
};
</script>

<style scoped>
#pdf-container {
  position: relative;
}
·to-pdf {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  position: fixed;
  top: 0;
}
</style>