<template>  
  <ion-item lines="inset">
    <ion-button type="button" fill="clear" @click="getEvidencia">
      <ion-icon slot="start" :icon="camera"></ion-icon>
      <ion-label>Agregar Imagenes</ion-label>
      <ion-input
        type="file"
        @ionChange="onFileSelected"
        hidden
        v-model="images"
        multiple
      ></ion-input>
    </ion-button>
  </ion-item>
</template>

<script>
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { camera} from "ionicons/icons";
import { Capacitor } from "@capacitor/core";
import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";
export default {
  name: "add-images",
  components: { IonButton, IonIcon, IonItem, IonLabel, IonInput },
  data() {
    return {
      camera
    };
  },
  computed: {
    ...mapGetters("item", ["images"]),
  },
  methods: {
    ...mapActions("item", ["setImages"]),

    onFileSelected(event) {
      this.images = event.target.value;
    },

    async takePhoto() {
      let image = null;
      const isAvailable = Capacitor.isPluginAvailable("Camera");
      if (!isAvailable) {
        console.log(isAvailable);
      } else {
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.DataUrl,
          allowEditing: true,
          source: CameraSource.Prompt,
          quality: 60,
          direction: CameraDirection.Rear,
        }).catch(() => {
          this.toatsColor = "warning";
          this.toatsMessage = `¡Debes enviar una imagen`;
          this.openToast();
        });

        if (photo) {
          image = photo && photo.dataUrl;
        }
        return image;
      }
    },
    async getEvidencia() {
      const photo = await this.takePhoto();
      this.setImages(photo);
    },
  },
};
</script>

<style>
</style>
