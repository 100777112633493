<template>
  <base-layout>
    <ion-card>
      <ion-card-header>
        <ion-toolbar>
          <ion-card-title>{{ title }}</ion-card-title>
          <ion-buttons slot="end">
            <ion-button
              type="button"
              fill="clear"
              color="primary"
              @click="save()"
            >
              <ion-icon slot="start" :icon="saveOutline"></ion-icon>
              <ion-label>Guardar</ion-label>
            </ion-button>            
          </ion-buttons>
        </ion-toolbar>
      </ion-card-header>
      <ion-card-content>
        <item-equipos-component></item-equipos-component>
        <item-equipo-new></item-equipo-new>
      </ion-card-content>
    </ion-card>
  </base-layout>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  toastController,
  loadingController,
  IonLabel
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { close, saveOutline } from "ionicons/icons";
import ItemEquiposComponent from "../../item/ItemEquiposComponent.vue";
import ItemEquipoNew from "../../item/ItemEquipoNew.vue";

export default {
  name: "equipo-modal",
  props: {
    title: { type: String, default: "Equipo" },
    actividadId: { type: Number, default: null },
  },
  computed: {
    ...mapGetters("actividad", ["currentModal"]),
    ...mapGetters("item", ["equipos"]),
  },
  data() {
    return {
      content: "Content",
      close,
      saveOutline,
      timeout: 1000,
    };
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    ItemEquiposComponent,
    ItemEquipoNew,
    IonLabel
  },
  methods: {
    ...mapActions("actividad", [
      "setModal",
      "updateMateriales",
      "updateEquipos"])
    ,
    dismissModal() {
      if (this.currentModal) {
        this.currentModal.dismiss().then( async () => {
          await this.setModal(null);
        });
      }
    },
    async save() {
      this.timeout = 10000;
      const loading = await loadingController.create({
        message: "Enviando información...",
        duration: this.timeout,
      });
      await loading.present();
      if (this.actividadId !== null) {
        const result = await this.updateEquipos({
          id: this.actividadId,
          equipos: this.equipos,
        });
        if (result.status === 200) {
          this.toatsMessage = "Equipos actualizados exitosamente";
          this.toatsColor = "light";
          this.openToast();
          this.dismissModal();
        } else if (result.status === 500) {
          this.toatsColor = "danger";
          this.toatsMessage = `¡Error interno del servidor!.<br>Contacte al administrador`;
          this.openToast();
        } else if (result.status === 422) {
          let errorMessage = "";
          const errors = await JSON.parse(JSON.stringify(result.data.errors));
          for (const i in errors) {
            errorMessage += `${errors[i][0]}\n`;
          }
          this.toatsColor = "danger";
          this.toatsMessage = `No se ha enviado toda la información<br>¡Erores!<br>${errorMessage}`;
          await this.openToast();
        } else {
          this.toatsColor = "danger";
          this.toatsMessage = "Error. No se ha realizado el registro";
          this.openToast();
        }
      }      
      loading.dismiss();
    },
    async openToast() {
      const toast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 2000,
      });
      return toast.present();
    },
  },
};
</script>
